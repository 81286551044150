<template>
  <div class="page">
    <!--pages/myposter/myposter.wxml-->
    <img class="tg-img" mode="widthFix" src="~images/yunmengshan.png">
    <div class="btn" @click="save">保存海报</div>
  </div>
</template>

<script>
  export default {
    name: 'myposter',
    data() {
      return {
        indicatorDots: false,
        circular: false,
        autoplay: false,
        interval: 3000,
        duration: 500,
        swiperIndex: 0,
        poster:[],
        current:'',
      }
    },
    created() {
      this.getPoster(0)
    },
    methods: {
      save() {
      },
      /**
       * 获取推广海报
       */
      getPoster:function(refesh=0){
        let that = this;
        this.post(
          this.API.getPoster,
          {refesh}
        ).then(response => {
          this.poster = response.data.poster.data
        })
      },
      bindchange(e) {
        var poster = this.data.poster;
        this.setData({
          swiperIndex: e.detail.current,
          current: poster[e.detail.current],
        })
      },
      savePosterPath: function () {
        var that = this;
        wx.downloadFile({
          url: that.data.current,
          success(resFile) {
            console.log(resFile)
            if (resFile.statusCode === 200) {
              wx.getSetting({
                success(res) {
                  if (!res.authSetting['scope.writePhotosAlbum']) {
                    wx.authorize({
                      scope: 'scope.writePhotosAlbum',
                      success() {
                        wx.saveImageToPhotosAlbum({
                          filePath: resFile.tempFilePath,
                          success: function (res) {
                            wx.showToast({
                              title: '保存成功',
                              icon: 'success',
                              duration: 1500,
                            })
                          },
                          fail: function (res) {
                            wx.showToast({
                              title: res.errMsg,
                              icon: 'none',
                              duration: 1500,
                            })
                          },
                          complete: function (res) { },
                        })
                      }
                    })
                  } else {
                    wx.saveImageToPhotosAlbum({
                      filePath: resFile.tempFilePath,
                      success: function (res) {
                        wx.showToast({
                          title: '保存成功',
                          icon: 'success',
                          duration: 1500,
                        })
                      },
                      fail: function (res) {
                        wx.showToast({
                          title: res.errMsg,
                          icon: 'none',
                          duration: 1500,
                        })
                      },
                      complete: function (res) { },
                    })
                  }
                }
              })
            }else{
              wx.showToast({
                title: resFile.errMsg,
                icon: 'none',
                duration: 1000,
                mask: true,
              })
            }
          },
          fail(res) {
            wx.showToast({
              title: res.errMsg,
              icon: 'none',
              duration: 1000,
              mask: true,
            })
          }
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #777777;
  }
  .tg-img {
    display: block;
    margin: 32px auto;
    width: 602px;
  }
  .btn {
    margin: 42px auto;
    width: 690px;
    height: 80px;
    line-height: 80px;
    border-radius: 40px;
    text-align: center;
    background-color: #FCC601;
    color: #FFFFFF;
    font-size: 32px;
  }

</style>
